
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationsAccountService } from '../services/integrations-account.service';
@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        IntegrationsAccountService
    ]
})
export class SharedModule { }